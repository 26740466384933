import React, { Component } from "react";
import { Elements, StripeProvider, CardElement } from "react-stripe-elements";
import { loadStripe } from "@stripe/stripe-js";
import Header from "./components/header.js";
import Footer from "./components/footer.js";
import Donate from "./components/donate.js";
import DonationOptions from "./components/donation-options.js";
import LearnMoreButton from "./components/learn-more.js";
import SocialComponent from "./components/social-component.js";
import Checkout from "./pages/page-donate.js";
import { Link, withRouter } from "react-router-dom";
import heroImage from "./images/hero_image.png";
import "./App.scss";
const data = require("./data/data.json");

class App extends Component {
  renderBody = () => {
    return (
      <div>
        {data.body.map((paragraph) => {
          return <p>{paragraph}</p>;
        })}
      </div>
    );
  };

  render() {
    return (
<div>
        <Header />
        <div className="row">
          <div className="double-column">
            <div className="column">
              <div className="section-main">
                <div className="section-main-title">
                  <img src={heroImage}></img>
                  <div style={{ minWidth: "300px" }}></div>
                  <div className="topTitle">
                    <h1>{data.title}</h1>
                  </div>
                </div>
                <div className="section-main-description">
                  <p>
                  Greater Boston’s racial wealth gap persists and experts agree that homeownership is key to closing that gap. In 2022, the homeownership rate in Boston was 44% for white residents, while it was 30% for Black residents and only 17% for Latino residents.
                  </p>

                  <p id="bold">
                  The Welcome Home Fund is a 100% philanthropic fund that addresses the racial and economic divides in homeownership by giving supplemental, unrestricted cash to first-generation homebuyers (primarily women of color and single mothers from Boston).
                  </p>

                  <p>
                  The Muncey Group selected the nonprofit <a href="https://www.compassworkingcapital.org/">Compass Working Capital</a> as its partner for the fund. Compass Working Capital runs a nationally-recognized program that supports families with low income to build assets and financial capabilities as a pathway out of poverty.
                  </p>

                  <p>
                  Compass Working Capital selected the families that were the best fit for grants to help them with a down payment on a home and the Muncey Group and another partner funder (the James Pace Clayton Fund) provided the funds --- no strings attached.
                  </p>

                  <p id="bold">
                  During Phase 1, five families were selected and each given $20,000 to help make their homeownership dreams a reality. With support from the fund, five of Greater Boston’s families — led by single mothers of color — are now able to change their generational wealth for themselves and their children.
                  </p>
                  <p>
                  Most people get a little help along their path to buying their first home. Let’s pay it forward and help those who will be first-generation homeowners realize their dream.
                  </p>

                  <p id="bold">JOIN US BY DONATING TO THE WELCOME HOME FUND</p>

                  <p id="bold">
                  About the Muncey Group
                  </p>

                  <p>The Muncey Group is a Boston-based real estate firm dedicated to serving Boston’s neighborhoods. Their mission is to cultivate vibrant communities and their team strives to bring homes and people together in a seamless real estate experience. Yet, the Muncey Group’s commitment to their communities extends far beyond selling real estate. The Muncey Group believes that economic and racial diversity is vital to the fabric of strong communities and strives to be an ally and partner to social justice organizations in the community.</p>
                </div>
              </div>
            </div>
          </div>

          <div className="column">
            <div className="section-donate">
              <Donate />
              <DonationOptions />
              <SocialComponent />
              <div class="section-donate-disclaimer">
                *While we work to ensure accuracy, the stats indicated on this
                page may not be an exact reflection of actual activity.
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(App);
